import type { ButtonProps } from '@mui/material/Button';
import Button from '@mui/material/Button';
import type { DialogProps } from '@mui/material/Dialog';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import type { ReactNode } from 'react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useOpen } from '../hooks/useOpen';

interface Props extends Omit<ButtonProps, 'onClick'> {
    dialogTitle: ReactNode;
    dialogContent: ReactNode;
    onConfirm: () => void;
    dialogProps?: Omit<DialogProps, 'onClose' | 'open'>;
}

const ConfirmButton: React.FC<Props> = ({ dialogTitle, dialogContent, onConfirm, dialogProps, ...rest }) => {
    const { open, onOpen, onClose } = useOpen();

    const handleConfirm = () => {
        onConfirm();
        onClose();
    };

    return (
        <>
            <Button {...rest} onClick={onOpen} />
            <Dialog {...dialogProps} open={open} onClose={onClose}>
                <DialogTitle>{dialogTitle}</DialogTitle>
                <DialogContent>{dialogContent}</DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>
                        <FormattedMessage id="confirmation.cancel" />
                    </Button>
                    <Button variant="contained" onClick={handleConfirm}>
                        <FormattedMessage id="confirmation.ok" />
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ConfirmButton;
