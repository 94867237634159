import { getConfig } from '@realcity/web-frame/lib/config';

interface AppConfig {
    BUG_REPORT_URL?: string;
    DOCUMENTS_URL?: string;
    DEFAULT_PAGE?: string;
    PREDEFINED_API_KEYS?: string;
    API_KEY_PICKER_ENABLED?: string;
}

interface PredefinedKey {
    apiKey: string;
    name: string;
}

const APP_CONFIG = getConfig<AppConfig>();
export const API_KEY_PICKER_ENABLED = APP_CONFIG.API_KEY_PICKER_ENABLED === 'true';
export const PREDEFINED_KEYS: readonly PredefinedKey[] = APP_CONFIG.PREDEFINED_API_KEYS ? JSON.parse(APP_CONFIG.PREDEFINED_API_KEYS) : [];

export default APP_CONFIG;
