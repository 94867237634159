import IfRoleOrUnauthorizedFeatureMessage from '@realcity/web-frame/lib/components/Auth/IfRoleOrUnauthorizedFeatureMessage';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Role from './Role';
import ApiKeys from './scenes/ApiKeys';
import Maintenance from './scenes/Maintenance';
import Messages from './scenes/Messages';
import SendMessage from './scenes/SendMessage';
import UserDetails from './scenes/UserDetails';
import Users from './scenes/Users';
import CreateAlertForm from './scenes/CreateAlertForm';
import UpdateAlertForm from './scenes/UpdateAlertForm';
import APP_CONFIG from './config';

const RedirectToRoot = () => (<Redirect to={APP_CONFIG.DEFAULT_PAGE ?? '/users'} />);

const Routes: React.FunctionComponent = () => (
    <Switch>
        <Route exact path="/users" component={Users} />
        <Route
            path="/users/:id"
            render={({ match }) => (
                <IfRoleOrUnauthorizedFeatureMessage role={Role.USERS_READ}>
                    <UserDetails userId={match.params.id} />
                </IfRoleOrUnauthorizedFeatureMessage>
            )}
        />
        <Route path="/api-keys" component={ApiKeys} />
        <Route
            path="/maintenance/new"
            render={() => (
                <IfRoleOrUnauthorizedFeatureMessage role={Role.MAINTENANCE_WRITE}>
                    <CreateAlertForm />
                </IfRoleOrUnauthorizedFeatureMessage>
            )}
        />
        <Route
            path="/maintenance/edit/:alertId"
            render={({ match }) => (
                <IfRoleOrUnauthorizedFeatureMessage role={Role.MAINTENANCE_WRITE}>
                    <UpdateAlertForm alertId={match.params.alertId} />
                </IfRoleOrUnauthorizedFeatureMessage>
            )}
        />
        <Route path="/maintenance" component={Maintenance} />
        <Route path="/messages/new" component={SendMessage} />
        <Route path="/messages" component={Messages} />
        <Route path="*" component={RedirectToRoot} />
    </Switch>
);

export default Routes;
