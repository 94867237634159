import type { ApolloError, MutationResult } from '@apollo/client';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import type { ReactNode } from 'react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import MessageDialog from './MessageDialog';

interface Props {
    mutationResult: MutationResult;
    finishedDialogContent?: ReactNode;
    customErrorMessage?: (error: ApolloError) => ReactNode;
}

const MutationProgressDialog: React.FC<Props> = ({ mutationResult, finishedDialogContent, customErrorMessage }) => {
    // eslint-disable-next-line  @typescript-eslint/unbound-method
    const { loading, data, error, reset } = mutationResult;

    return (
        <>
            <Dialog open={loading}>
                <DialogContent>
                    <CircularProgress />
                </DialogContent>
            </Dialog>
            {finishedDialogContent && (
                <Dialog open={Boolean(data)}>
                    {finishedDialogContent}
                </Dialog>
            )}
            {error && (
                <MessageDialog open onClose={reset}>
                    {customErrorMessage?.(error) || <FormattedMessage id="mutation.error" />}
                </MessageDialog>
            )}
        </>
    );
};

export default MutationProgressDialog;
