import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import FormattedDateTime from '../../../components/FormattedDateTime';
import PaginatedTableView from '../../../components/PaginatedTableView';
import QueryResultRenderer from '../../../components/QueryResultRenderer';
import { TableDescriptor } from '../../../components/TableView/TableDescriptor';
import type { ValidationInfoFragment, ListValidationsQuery } from '../../../graphql/graphql-typings';
import { useListValidationsQuery } from '../../../graphql/graphql-typings';

const DESCRIPTOR = new TableDescriptor<ValidationInfoFragment>()
    .column(<FormattedMessage id="validation.date" />, 'validationDate', date => <FormattedDateTime value={date} />)
    .column(<FormattedMessage id="validation.result" />, 'validationResult', (result) => {
        switch (result) {
            case 'SUCCESSFUL':
                return <FormattedMessage id="validation.result.successful" />;
            case 'UNSUCCESSFUL':
                return <FormattedMessage id="validation.result.unsuccessful" />;
            default:
                return null;
        }
    });

interface Props {
    purchaseId: number;
}

const Validations: React.FC<Props> = ({ purchaseId }) => {
    const queryResult = useListValidationsQuery({
        fetchPolicy: 'network-only',
        variables: {
            purchaseId,
        },
    });

    const component = useCallback(
        ({ validations }: ListValidationsQuery) => <PaginatedTableView descriptor={DESCRIPTOR} items={validations} />,
        [],
    );

    return (
        <QueryResultRenderer
            queryResult={queryResult}
            component={component}
        />
    );
};

export default Validations;
