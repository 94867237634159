import BugReportOutlined from '@mui/icons-material/BugReportOutlined';
import BuildOutlined from '@mui/icons-material/BuildOutlined';
import ForumOutlined from '@mui/icons-material/ForumOutlined';
import InsertDriveFileOutlined from '@mui/icons-material/InsertDriveFileOutlined';
import PeopleAltOutlined from '@mui/icons-material/PeopleAltOutlined';
import VpnKeyOutlined from '@mui/icons-material/VpnKeyOutlined';
import IfRole from '@realcity/web-frame/lib/components/Auth/IfRole';
import { SidebarItem } from '@realcity/web-frame/lib/components/Sidebar/SidebarItem';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import APP_CONFIG from '../../config';
import Role from '../../Role';

const MainMenu: React.FunctionComponent = () => (
    <>
        <IfRole role={Role.USERS_READ}>
            <SidebarItem icon={PeopleAltOutlined} text={<FormattedMessage id="users.title" />} path="/users" />
        </IfRole>
        <IfRole role={Role.API_KEYS_READ}>
            <SidebarItem icon={VpnKeyOutlined} text={<FormattedMessage id="api-keys.title" />} path="/api-keys" />
        </IfRole>
        <IfRole role={Role.MAINTENANCE_READ}>
            <SidebarItem icon={BuildOutlined} text={<FormattedMessage id="maintenance.title" />} path="/maintenance" />
        </IfRole>
        <IfRole role={Role.NOTIFICATIONS_READ}>
            <SidebarItem icon={ForumOutlined} text={<FormattedMessage id="messages.title" />} path="/messages" />
        </IfRole>
        {APP_CONFIG.DOCUMENTS_URL && (
            <SidebarItem
                icon={InsertDriveFileOutlined}
                text={<FormattedMessage id="documents.title" />}
                external
                path={APP_CONFIG.DOCUMENTS_URL}
            />
        )}
        {APP_CONFIG.BUG_REPORT_URL && (
            <SidebarItem
                icon={BugReportOutlined}
                text={<FormattedMessage id="bug-report.title" />}
                external
                path={APP_CONFIG.BUG_REPORT_URL}
            />
        )}
    </>
);

export default MainMenu;
