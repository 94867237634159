import type { ThemeOptions } from '@mui/material/styles/createTheme';
import createTheme from '@mui/material/styles/createTheme';
import { configureStore } from '@realcity/web-frame';
import { AuthWrapper, RequireAuth } from '@realcity/web-frame/lib/components/Auth';
import { Base } from '@realcity/web-frame/lib/components/Base';
import IntlProvider from '@realcity/web-frame/lib/components/Intl/Provider';
import CustomTheme from '@realcity/web-frame/lib/theme/CustomTheme';
import Light from '@realcity/web-frame/lib/themes/Light';
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import GraphqlWrapper from './providers/GraphqlWrapper';
import Routes from './Routes';
import MainMenu from './scenes/MainMenu';
import messages from './translations';

const store = configureStore({});
const authOptions = {};

const themeOptions: ThemeOptions = {
    typography: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
    components: {
        MuiTypography: {
            variants: [
                {
                    props: { variant: 'h1' },
                    style: {
                        fontSize: 24,
                        marginBottom: 32,
                        fontWeight: 700,
                    },
                },
                {
                    props: { variant: 'h2' },
                    style: {
                        fontSize: 16,
                        margin: '32px 0 16px',
                        fontWeight: 400,
                        textTransform: 'uppercase',
                        letterSpacing: 1,
                    },
                },
            ],
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    minWidth: 0,
                },
            },
        },
    },
    pagePadding: 4,
};

const theme = createTheme(themeOptions, Light);

const App: React.FC = () => (
    <Provider store={store}>
        <IntlProvider supportedLocales={['en', 'hu']} messages={messages}>
            <AuthWrapper options={authOptions}>
                <RequireAuth>
                    <BrowserRouter>
                        <GraphqlWrapper>
                            <CustomTheme theme={theme}>
                                <Base
                                    appName="backoffice"
                                    appVersion={process.env.REACT_APP_VERSION!}
                                    useFeedback={false}
                                    menu={<MainMenu />}
                                    sidebarExpandType="NORMAL"
                                    openByDefault
                                    usePageFrame
                                    privateApp
                                    useFlavorFavicon
                                >
                                    <Routes />
                                </Base>
                            </CustomTheme>
                        </GraphqlWrapper>
                    </BrowserRouter>
                </RequireAuth>
            </AuthWrapper>
        </IntlProvider>
    </Provider>
);

export default App;
